<template>
  <form @submit.prevent="createSubjectTeacher()" action="">
    <ion-row>
      <ion-col size="12">
        <ion-list>
          <ion-item v-if="validationError" lines="none">
            <ion-label className="ion-text-wrap">
              <ion-text color="danger">
                <p v-for="error of v$.$errors" :key="error.$uid">
                  <ion-icon
                    :ios="alertCircleOutline"
                    :md="alertCircleSharp"
                    size="small"
                    style="vertical-align: middle"
                  ></ion-icon
                  >{{ error.$property }}: {{ error.$message }}
                </p></ion-text
              >
            </ion-label>
          </ion-item>
          <ion-item :class="{ 'color-danger': v$.teacher.$error }">
            <ion-label position="floating">Teacher</ion-label>
            <ion-select v-model="teacher">
              <ion-select-option
                v-for="teacher of teachers"
                :key="teacher.id"
                :value="teacher.id"
              >
                {{ teacher.user.user_detail.name }}
              </ion-select-option>
            </ion-select>
          </ion-item>
        </ion-list>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-button type="submit" expand="full" class="ion-margin-vertical">
          Save
        </ion-button>
      </ion-col>
    </ion-row>
  </form>
</template>

<script>
import {
  IonRow,
  IonCol,
  IonList,
  IonLabel,
  IonItem,
  IonSelect,
  IonSelectOption,
  IonIcon,
  IonButton,
  IonText,
  loadingController,
  alertController,
} from "@ionic/vue";

import { alertCircleOutline, alertCircleSharp } from "ionicons/icons";

import { required, integer, helpers } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

export default {
  props: ["subject_id"],
  emits: ["closeModal"],
  components: {
    IonRow,
    IonCol,
    IonList,
    IonLabel,
    IonItem,
    IonSelect,
    IonSelectOption,
    IonIcon,
    IonButton,
    IonText,
  },
  data() {
    return {
      teacher: null,

      validationError: null,

      alertCircleOutline,
      alertCircleSharp,

      xhrError: {
        header: null,
        description: null,
      },
    };
  },

  computed: {
    teachers() {
      return this.$store.getters["teacher/allResources"];
    },
  },

  setup() {
    return { v$: useVuelidate() };
  },

  validations() {
    return {
      subject_id: {
        required: helpers.withMessage("is required", required),
        integer: helpers.withMessage("can only be integers", integer),
      },
      teacher: {
        required: helpers.withMessage("is required", required),
        integer: helpers.withMessage("can only be integers", integer),
      },
    };
  },

  methods: {
    setModalState(state) {
      this.modalState = state;
    },

    loader(message) {
      const loading = loadingController.create({
        message: message,
        backdropDismiss: false,
      });

      return loading;
    },

    alerter(header, message, buttons = ["close"]) {
      const alert = alertController.create({
        header: header,
        message: message,
        buttons: buttons,
      });

      return alert;
    },

    setErrorResponse(error) {
      if (error.response) {
        this.xhrError.header = error.response.data.header;
        this.xhrError.message = error.response.data.message;
      } else if (error.request) {
        this.xhrError.header = error.message || error.request;
        this.xhrError.message = "Please check your connection and try again";
      } else {
        this.xhrError.header = error.message;
        this.xhrError.message = "Something went wrong. Try again later";
      }
    },

    async createSubjectTeacher() {
      this.v$.$touch();
      if (this.v$.$error) {
        this.validationError = true;
        return;
      }

      let data = {
        subject_id: this.subject_id,
        teacher_id: this.teacher,
      };

      const spinner = await this.loader("Saving...");
      spinner.present();

      try {
        await this.$store.dispatch("subjectTeacher/createResource", data);
        spinner.dismiss();

        this.name = null;
        this.$emit("closeModal");
      } catch (error) {
        spinner.dismiss();

        this.setErrorResponse(error);

        const alertDialog = await this.alerter(
          this.xhrError.header,
          this.xhrError.message
        );

        alertDialog.present();

        this.password = null;
        this.validationError = null;
      }
    },

    async fetchTeachers() {
      await this.$store.dispatch("teacher/allResources");
    },
  },
  created() {
    this.fetchTeachers();
  },
};
</script>