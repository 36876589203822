<template>
  <ion-list v-if="resources.data.length > 0">
    <ion-list-header class="ion-margin-bottom">
      <h3>Assigned Teachers</h3>
    </ion-list-header>

    <ion-item
      v-for="item in resources.data"
      :key="item.id"
      :ref="`items-${item.id}`"
      class="hydrated link"
    >
      <ion-label @click="openPopover(item, $event)">
        <h2>
          {{ item.teacher.user.user_detail.name }}
        </h2>
      </ion-label>
    </ion-item>
  </ion-list>
  <ion-list v-else>
    <ion-list-header>
      <p>No teacher found</p>
    </ion-list-header>
  </ion-list>

  <ion-popover
    :is-open="popoverState"
    :event="popoverEvent"
    @didDismiss="setPopoverState(false)"
  >
    <base-popover
      @closePopover="setPopoverState(false)"
      :title="selectedResource.teacher.user.user_detail.name"
    >
      <ion-item
        button
        @click="
          confirmDelete({
            id: selectedResource.id,
            subjectId: selectedResource.subject_id,
            name: selectedResource.teacher.user.user_detail.name,
          })
        "
        >Delete</ion-item
      >
    </base-popover>
  </ion-popover>
</template>
<script>
import BasePopover from "../../../Base/BasePopover.vue";
import {
  IonList,
  IonListHeader,
  IonLabel,
  IonItem,
  IonPopover,
  alertController,
} from "@ionic/vue";

export default {
  props: {
    resources: {
      type: Object,
      required: true,
    },
  },

  components: {
    IonList,
    IonListHeader,
    IonLabel,
    IonItem,
    IonPopover,
    BasePopover,
  },
  data() {
    return {
      popoverState: false,
      popoverEvent: null,
      selectedResource: null,

      xhrError: {
        header: null,
        description: null,
      },
    };
  },
  methods: {
    slide(id) {
      this.$refs[`items-${id}`].$el.open();
    },

    alerter(header, message, buttons = ["close"]) {
      const alert = alertController.create({
        header: header,
        message: message,
        buttons: buttons,
      });

      return alert;
    },

    setErrorResponse(error) {
      if (error.response) {
        this.xhrError.header = error.response.data.header;
        this.xhrError.message = error.response.data.message;
      } else if (error.request) {
        this.xhrError.header = error.message || error.request;
        this.xhrError.message = "Please check your connection and try again";
      } else {
        this.xhrError.header = error.message;
        this.xhrError.message = "Something went wrong. Try again later";
      }
    },

    async confirmDelete(resource) {
      this.setPopoverState(false);
      const header = `Unassign ${resource.name} ?`;
      const message = "This action cannot be undone.";
      const buttons = [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
        },
        {
          text: "Okay",
          handler: () => {
            this.delete(resource.id, resource.subjectId);
          },
        },
      ];
      const alertDialog = await this.alerter(header, message, buttons);

      alertDialog.present();
    },

    async delete(resourceId, subjectId) {
      try {
        const payLoad = {
          resourceId: resourceId,
          subjectId: subjectId,
        };

        await this.$store.dispatch("subjectTeacher/deleteResource", payLoad);
      } catch (error) {
        this.setErrorResponse(error);
        const alertDialog = await this.alerter(
          this.xhrError.header,
          this.xhrError.message
        );
        alertDialog.present();
      }
    },

    openPopover(resource, ev) {
      this.popoverEvent = ev;
      this.setSelectedResource(resource);
      this.setPopoverState(true);
    },

    setPopoverState(state) {
      this.popoverState = state;
    },

    setSelectedResource(resource) {
      this.selectedResource = resource;
    },
  },
};
</script>
